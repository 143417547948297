import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from 'rebass/styled-components'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import { withI18next } from 'gatsby-plugin-i18n'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'

export const ContactPageTemplate = ({ title, image, content }) => {
  return (
    <>
      {image && (
        <Box as="header" sx={{ height: ['10rem', '12rem', '20rem'] }}>
          <BackgroundImage
            fluid={image.local.childImageSharp.fluid}
            style={{ backgroundPosition: '50% 80%', height: '100%' }}
          />
        </Box>
      )}
      <main>
        <LightSection>
          <Container>
            <SectionTitle as="h2">{title}</SectionTitle>

            <Box
              sx={{ '& *': { color: 'primary' } }}
              dangerouslySetInnerHTML={{
                __html: draftjsContentToHTML(content),
              }}
            />
          </Container>
        </LightSection>
      </main>
    </>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.object,
  translations: PropTypes.array,
}

const ContactPage = ({ data, pageContext }) => {
  const { page, translations } = data
  const { title, content, image } = page
  const { language } = pageContext

  return (
    <Layout language={language} translations={translations}>
      <ContactPageTemplate
        title={title || 'Contact'}
        image={image || null}
        content={content || ''}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      image: PropTypes.object,
    }),
    translations: PropTypes.array,
  }),
  pageContext: PropTypes.string.isRequired,
}

export default withI18next()(ContactPage)

export const pageQuery = graphql`
  query ContactPageTemplate($language: String!, $translatedIds: [String]) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    translations: allPages(filter: { _id: { in: $translatedIds } }) {
      edges {
        node {
          id
          title
          slug
          language
        }
      }
    }
    page: pages(template: { eq: "contact" }, language: { eq: $language }) {
      title
      content
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
